/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close': {
    width: 14,
    height: 15,
    viewBox: '0 0 14 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.012 8L14 13.988 12.987 15 7 9.012 1.012 15 0 13.988 5.987 8 0 2.012 1.012 1 7 6.988 12.987 1 14 2.012 8.012 8z" _fill="#202020" fill-opacity=".3"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M8.012 7L14 12.988 12.987 14 7 8.012 1.012 14 0 12.988 5.987 7 0 1.012 1.012 0 7 5.988 12.987 0 14 1.012 8.012 7z" _fill="#fff"/>'
  }
})
